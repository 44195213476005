import React from "react"
import ReactMarkdown from 'react-markdown'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'

import Layout from '../components/Layout/Layout'
import styles from '../styles/about.module.css'

export default function About({ data }) {
  return (
    <Layout title="About">
      <div>
        <h1>{data.markdownRemark.frontmatter.header}</h1>
        <div className={styles.about_row}>
          {data.markdownRemark.frontmatter.aboutPhoto ? (
            <div className={styles.about_image}>
              <Img fluid={data.markdownRemark.frontmatter.aboutPhoto.photo.childImageSharp.fluid} alt={data.markdownRemark.frontmatter.aboutPhoto.altText} />
            </div>
          )
            : null}
          <div className={styles.about_text}>
            <ReactMarkdown source={data.markdownRemark.frontmatter.description} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`query AboutQuery {
  markdownRemark(frontmatter: {title: {eq: "About"}}) {
    frontmatter {
      header
      description
      aboutPhoto {
        photo {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        altText
      }
    }
  }
}
`